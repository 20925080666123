#ccp-container {
  height:408px;
  width: 672px;
  margin-left: 20px;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
} 

#refresh{
  background-color:#CC0000;
  color: white;
  height: 44px;
  width: 55px;
}

#tickettop{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
}

#refresh[disabled]{
  border: 1px solid #999999;
  background-color: white ;
  color: #999999;
}

#grid-container {
  display: grid;
  grid-column: auto;
  padding-top: 20px;
  margin-left: -20px;
  margin-right:-10px;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ .coloring.red:before {
background: #F25F5C !important;
}

.App {
  display:-webkit-flex;
  align-items:stretch;
  justify-content:space-evenly;
}