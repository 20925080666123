body {
  margin: 0;
}

p {
  margin: 0;
}

.chatroom {
  width: 672px;
  height: 408px;
  top: 964px;
  background-color: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
}

.chatroom h3 {
  background-color: #CC0000;
  color: #FFF;
  margin: 0;
  padding-left: 20px;
  padding-top: 10px;
  height: 60px;
  font-size: 24px;
  display: flex; 
}

.chatroom .chats {
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 0 0;
  height: 290px;
  overflow-y: scroll;
}

.chatroom .chats .chat {
  background: #EDEDED;
  position: relative;
  padding: 5px 13px;
  font-size: 14px;
  border-radius: 10px;
  list-style: none;
  float: left;
  clear: both;
  margin: 10px 0;
  width: 250px;
  overflow-wrap: break-word;
}



.chatroom .chats .chat img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 0px;
}

.chatroom .chats .chat.right {
  float: right;
  clear: both;
  background: #E8F6F8;
}

.chatroom .chats .chat a {
  text-decoration: none;
  color:#3498DB;
}

.translatedMessage {
  text-decoration: none;
  color: red;
}

.chatroom .input {
  height: 60px;
  width: 100%;
  background: #F2F2F2;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatroom .input input[type="text"] {
  background: #FFFFFF;
  outline: 0;
  border: 2px solid #4A4A4A;
  padding: 0 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 10px;
  height: 40px;
  width: 501px;
  box-sizing: border-box;
  font-size: 16px;
}

.chatroom .input input[type="submit"] {
  background: transparent;
  border: 0px solid #ffffff;
  color: #ffffff;
  background-color: #CC0000;
  font-size: 18px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 99px;
  height: 40px;
  left: 549px;
  border-radius: 8px;
  margin-left: 30px;
  transition-duration: 0.4s;
}


.chatroom .input input[type="submit"]:hover {
  background: #9b0202;
  color: white;
}

