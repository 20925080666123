.floating-form {
  width:320px;
}

/****  floating-Lable style start ****/
.floating-label { 
  position:relative; 
  margin-bottom:20px; 
}
.floating-input , .floating-select {
  font-size:14px;
  padding:4px 4px;
  display:block;
  width:100%;
  height:30px;
  background-color: transparent;
  border:none;
  border-bottom:1px solid #757575;
}

.floating-input:focus , .floating-select:focus {
     outline:none;
     border-bottom:2px solid #5264AE; 
}

#file-label{
  background-color: indigo;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

#file-chosen{
  margin-left: 0.3rem;
  font-family: sans-serif;
}

label {
  color:#999; 
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:5px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:-18px;
  font-size:14px;
  color:#5264AE;
}

.floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
  top:-18px;
  font-size:14px;
  color:#5264AE;
}

/* active state */
.floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
  width:50%;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.floating-textarea {
   min-height: 30px;
   max-height: 260px; 
   overflow:hidden;
  overflow-x: hidden; 
}

/* highlighter */
.highlight {
  position:absolute;
  height:50%; 
  width:100%; 
  top:15%; 
  left:0;
  pointer-events:none;
  color: #000;
  opacity:0.5;
}

/* ticket */
#ticket{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
}

#tickettop{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
}

#download{
  background-color:#CC0000;
  color: white;
  height: 44px;
  width: 132px;
  font-weight: bolder;
}

#downloadsm{
  background-color:#CC0000;
  color: white;
  font-weight: bolder;
}

/* active state */
.floating-input:focus ~ .highlight , .floating-select:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* animation */
@-webkit-keyframes inputHighlighter {
    from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
    from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
    from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}

/****  floating-Lable style end ****/
.floating-credit { position:fixed; bottom:10px;right:10px; color:#aaa; font-size:13px;font-family:arial,sans-serif; }
.floating-credit a { text-decoration:none; color:#000000; font-weight:bold; }
.floating-credit a:hover { border-bottom:1px dotted #f8f8f8; }
.floating-heading { position:fixed; color:#aaa; font-size:20px; font-family:arial,sans-serif; }

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  color: #CC0000;
}
.pagination > .active > a{
  background-color: #cc0000 ;
  border-color: #cc0000 ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #cc0000 ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #cc0000 ;
  border-color: #cc0000;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #cc0000
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

 #navBar {
  border-top-style:solid;
  border-bottom-color: #8b8b8b;
  box-shadow: 0 8px 6px -6px #999;
};

.linkActive{
  border-bottom-style: solid;
  border-bottom-color:#cc0000;
  border-bottom-width: 12px;
  color: #cc0000;
};

#linkActive2{
  color: #cc0000
};